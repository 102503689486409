<template>
  <div class="me_deposit_container">
    <div class="header">
      <van-field readonly clickable label="选择住院人" :value="patName" @click="patNamePickerShow = true"
        right-icon="arrow-down" />
      <van-popup v-model="patNamePickerShow" round position="bottom">
        <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
      </van-popup>
    </div>
    <div class="list">
      <div v-for="(item, index) in hospitalizationRecords" :key="index" class="item"
        @click="navDetail(item.admissionNum)">
        <div class="top">
          <!-- <van-image class="avatar" width="60" height="60" src="http://www.fkxrmyycom/wxxcx/wx/image/doc.png"
            fit="contain" /> -->
          <div class="info">
            <div>科室：{{ item.deptName }}</div>
            <div>入院日期：{{ item.inDate }}</div>
            <div>出院日期：{{ item.outDate === '' || item.outDate === '0001-01-01' ? '-' : item.outDate }}</div>
          </div>
          <van-tag plain type="primary" size="large" class="status"
            v-if="hospitalizationRecords.status === '1'">在&nbsp;院</van-tag>
          <van-tag plain type="success" size="large" class="status"
            v-if="hospitalizationRecords.status === '2'">已&nbsp;出&nbsp;院</van-tag>
          <van-icon name="arrow" class="right" size="20px" />
        </div>
        <div class="bottom">住院按金：{{ item.balance }}元</div>
      </div>
    </div>
    <van-empty description="暂无内容" v-if="hospitalizationRecords === ''" />
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Deposit",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      patCardNo: "",
      hospitalizationRecords: []
    };
  },
  created () {
    this.patCardNo = this.$route.query.patCardNo;
    this.getMembers();
  },
  methods: {
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name);
        }
        if (this.patCardNo === undefined) {
          this.patNameIndex = 0;
        } else {
          this.patNameIndex = this.patNameArray.findIndex(item => {
            return item.pat_card_no === this.patCardNo;
          });
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getHospitalizationRecords();
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    //获取住院记录
    async getHospitalizationRecords () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost(
        "/wxapp/his/getHospitalizationRecords",
        {
          idCard: this.patNameArray[this.patNameIndex].card_no,
          patCardNo: this.patNameArray[this.patNameIndex].pat_card_no,
          patCardType: this.patNameArray[this.patNameIndex].pat_card_type
        }
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.hospitalizationRecords = res.data.item;
      } else {
        this.hospitalizationRecords = "";
        this.$toast.fail(res.msg);
      }
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getHospitalizationRecords();
    },
    navDetail (admissionNum) {
      this.$router.push({
        path: "/order/deposit/detail",
        query: {
          admissionNum: admissionNum
        }
      });
    }
  }
};
</script>

<style scope>
.me_deposit_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
}

.me_deposit_container .header .van-cell {
  border-bottom: 1px solid rgb(201, 191, 191);
  padding: 30px;
}

.me_deposit_container .header .van-cell::after {
  border: 0;
}

.me_deposit_container .header .van-field__label {
  text-align: left;
  font-size: 40px;
}

.me_deposit_container .header .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.me_deposit_container .header .van-icon {
  font-size: 40px;
}

.me_deposit_container .header .van-picker-column {
  font-size: 40px;
}

.me_deposit_container .header .van-picker__cancel,
.me_deposit_container .header .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.me_deposit_container .list {
  padding-bottom: 60px;
}

.me_deposit_container .list .item {
  position: relative;
  border-bottom: 1px solid #f5f5f5;
}

.me_deposit_container .list .item .top {
  position: relative;
  display: flex;
  font-size: 32px;
  align-items: center;
  padding: 20px 30px;
  border-bottom: 2px dotted rgb(201, 191, 191);
}

.me_deposit_container .list .item .top .avatar {
  flex: 1;
}

.me_deposit_container .list .item .top .info {
  flex: 4;
  margin-left: 20px;
  line-height: 70px;
}

.me_deposit_container .list .item .bottom {
  font-size: 40px;
  padding: 20px 30px;
}

.me_deposit_container .list .item .top .van-icon {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.me_deposit_container .list .item .top .van-tag {
  position: absolute;
  top: 50%;
  right: 90px;
  transform: translateY(-50%);
  font-size: 32px;
}
</style>